import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { PageProps } from "../shared";
import ImageGallery from 'react-image-gallery';
import { green } from "../shared/colors";

const Container = styled.div`
  max-width: 960px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .image-gallery {
    flex: 1;

    .image-gallery-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      
      .image-gallery-slide-wrapper {
        flex: 1;

        .image-gallery-swipe {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  button.image-gallery-thumbnail {
    &.active, &:hover {
      border-color: ${green};
    }
  }
`;

type GalleryProps = { images: { image: string, caption: '' }[]};

export const query = graphql`
{
  pageData: galleryJson {
    images {
      image
      caption
    }
  }
}
`;

const Gallery = (data: PageProps<GalleryProps>) => {
    const { images } = data.data.pageData;
    const galleryImages = images.map(i => {
        return {
          original: i.image,
          thumbnail: i.image,
          description: i.caption
        }
    });
    return (
        <Layout transparent={false}>
            <Seo title="Gallery" />
            <Container>
                <ImageGallery 
                  items={galleryImages}
                  lazyLoad={true}
                  useBrowserFullscreen={false}
                  showPlayButton={false}
                  showNav={true}
                  />
            </Container>
        </Layout>);
};

export default Gallery;

